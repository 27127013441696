







































































import {Component, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient, DinoDto} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';
import DinoName from '@/components/DinoName.vue';
import ServerName from '@/components/ServerName.vue';
import TribeName from '@/components/TribeName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, DinoName, PlayerName, ServerName, TribeName}
})
export default class DinoList extends Vue {
    private preFilter = '';

    private dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;
    private errorNoSuchDino = false;
    private tribeMap = {} as { [key: string]: string };
    private errorReason = 'No dinos found matching this filter';

    public created(): void {
        const queryParams = this.$route.query;
        this.preFilter = String(queryParams.preFilter) || '';
    }

    public dinoImg(type: string): string {
        return `/images/dinos/${type}.png`;
    }

    @AsyncComputedProp()
    public async dinos(): Promise<DinoDto[]> {
        if (!this.preFilter) {
            return [];
        }
        const preFilter = this.preFilter.replaceAll('\t','').replaceAll('\n','');
        try {
            const dinos = (await ArkStatsBackendClient.getDinos({
                filter: preFilter,
                fields: ['type', 'serverId', 'name', 'owner.tribeId', 'owner.playerId', 'stats.base.level', 'id1'],
                orderBy: 'name',
                limit: 5000
            })).data;
            if (!dinos || !dinos.length) {
                this.errorReason = 'No dinos found matching this filter';
                this.errorNoSuchDino = true;
                return [];
            }
            ArkStatsBackendClient.getGenericValues('dino', 'owner.tribeId', preFilter).then(tribeIds => {
                tribeIds = tribeIds.filter((tribeId) => !this.tribeMap[String(tribeId)]);
                if(tribeIds && tribeIds.length) {
                    const query = `tribeId=in=(${tribeIds.join(',')})`;
                    ArkStatsBackendClient.getTribes({filter: query, fields: ['name']}).then(tribes => {
                        tribes.data.forEach(tribe => {
                            Vue.set(this.tribeMap, String(tribe.id), tribe.name);
                        });
                    });
                }
            });
            this.errorNoSuchDino = false;
            return dinos;
        } catch(ex) {
            this.errorNoSuchDino = true;
            const errorReason = ex?.response?.data?.error?.reason;
            if(!errorReason) {
                this.errorReason = 'An unknown error occured';
            } else {
                this.errorReason = errorReason;
            }

            console.log(ex.response);
            return [];
        }
    }

    public localDate(timestamp: string | Date): string {
        return new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(new Date(timestamp));
    }

    get url(): string {
        const queryParams: string[] = [];
        queryParams.push('preFilter=' + encodeURIComponent(this.preFilter));
        const url = this.$route.path + '?' + queryParams.join('&');
        history.pushState({}, 'Title', url);
        return url;
    }

}

